import React from 'react';
import { Link } from 'react-router-dom';
import isoImage from '../assets/isoturns.png';
import slideThree from '../assets/slide-3.jpg';

const Isoturns = props => (
    <div>
        <div className="row subnav" style={{ display: 'flex', justifyContent: 'space-around' }}>
            <div style={{ display: 'flex', justifyContent: 'space-evenly', position: 'absolute', bottom: '4px', left: '80px', fontSize: '1.5em' }}>
                <Link className="white-text" to="/">Home</Link>
                <span className="grey-text text-darken-2" style={{ marginLeft: '4px', marginRight: '4px', fontWeight: '500' }}>/</span>
                <Link className="white-text" to="/articles">Articles</Link>
                <span className="grey-text text-darken-2" style={{ marginLeft: '4px', marginRight: '4px', fontWeight: '500' }}>/</span>
                <span className="grey-text text-darken-2">Isoturns</span>
            </div>
        </div>
        <div className="container">
            <div className="row">
                <h4>What is Isoturns?</h4>
                <p className="grey-text">By <span className="red-text">Jacob Record</span></p>
            </div>
            <div className="row">
                <img className="responsive-img" src={isoImage} alt="Isoturns" />
            </div>
            <div className="row">
                <h5>What is this Isoturns you keep talking about, and why should I care about it</h5>
            </div>
            <div className="row">
                <p className="grey-text">You may have noticed, we talk a lot about Isoturns and there is a good reason for that.  Isoturns forms the basis of everything we do.  From the way we process your medications to how you receive credits, all of it is driven by our Isoturns process.  Outdate Rx was founded with a commitment to solve the problems of reverse distribution and Isoturns is how we do that.</p>
                <p className="grey-text">Everything starts when we receive your returns at our warehouse.  Each return is carefully reconciled against shipping information to ensure that no packages were lost or delayed in transit.  After it is logged, the order is assigned out to one of our highly trained warehouse clerks.  The clerk will begin by sorting your returned medications to match up like items and check for any recalls.</p>
                <p className="grey-text">Each medication is then counted, logged and prepped for the next stage of the returns process.  Once all medications have been inventoried, a warehouse return processor takes over.  These employees are highly trained and familiar with all the variations in each manufacturer’s return policy.  A return processor will then be assigned to your order and they will double check the work of the inventory clerk for accuracy, request all return authorizations and physically match authorization paperwork, Outdate Rx invoicing and medication together into an individual package ready to be shipped.  At this point inventory reports are emailed directly to your pharmacy.  This process is generally completed within 3 days of receipt at our warehouse.</p>
            </div>
            <div className="row">
                <img className="responsive-img" src={slideThree} alt="computer" />
            </div>
            <div className="row">
                <p className="grey-text">Once the processor is finished with your returns, they are shipped immediately.  Within 30 days our accounting and credit recovery team contacts the manufacturer to confirm receipt of the returns and to begin the process of collecting the return credits and retrieving credit memo information.</p>
                <p className="grey-text">There are a few big differences here, rather than inventorying medications one by one we process your return as a whole return.  That results in opportunities to pair like items, reassemble broken packages and recover credits that might otherwise have been lost.  We ship your medication in a matter of days rather than the months it takes other reverse distributors to close their batches.  This shorter processing time mean less time waiting for credits.</p>
                <p className="grey-text">We also never combine your items with those of another pharmacy.  Each pharmacy gets individualized debit memos (invoices) for each product in their returns.  This means each product will be individually credited so your returns become your credits.  This small difference means more manufacturers will accept returns from us and it means you get exactly what you are entitled to.</p>
                <p className="grey-text">We see medications that were purchased at discount (whether shortdated sales, fire sales, manufacturer/ wholesaler, or some other reason), medications that were purchased in a non-returnable state, and even occasionally counterfeit medications.  Any of these medications will have their credit values reduced in line with the purchase price or purchase agreement.  In a batch there is no way to tell who should be credited with that loss so everyone shares in it.  That is simply unfair!</p>
                <p className="grey-text">At Outdate Rx, your returns become your credits and we have the manufacturer credit memos to prove it.</p>
            </div>
            <div className="col s12 m4 l2">
                <Link to="/articles" className="btn-large col s12 red white-text">Back to Articles</Link>
            </div>
        </div>
    </div >
)

export default Isoturns;