import React from 'react';
import indateImg from '../assets/indates.jpg';
import { Link } from 'react-router-dom';

const Indates = props => (
    <div>
        <div className="row subnav" style={{ display: 'flex', justifyContent: 'space-around' }}>
            <div style={{ display: 'flex', justifyContent: 'space-evenly', position: 'absolute', bottom: '4px', left: '80px', fontSize: '1.5em' }}>
                <Link className="white-text" to="/">Home</Link>
                <span className="grey-text text-darken-2" style={{ marginLeft: '4px', marginRight: '4px', fontWeight: '500' }}>/</span>
                <Link className="white-text" to="/services">Services</Link>
                <span className="grey-text text-darken-2" style={{ marginLeft: '4px', marginRight: '4px', fontWeight: '500' }}>/</span>
                <span className="grey-text text-darken-2">Indates</span>
            </div>
        </div>
        <div className="container">
            <div className="row">
                <h4>Indated Medications</h4>
                <div className="row">
                    <img className="responsive-img" src={indateImg} alt="Indates" />
                </div>
            </div>
            <div className="row">
                <h5>Service Overview</h5>
            </div>
            <div className="row">
                <p className="grey-text">Every pharmacy ends up with non-expired stock that they know will never be used.  Whether those products are the result of a formulary change, a change in practitioner prescription habits, damaged product, over stocked items or something else, it doesn’t matter to us.  </p>
                <p className="grey-text">You don’t need those medications sitting in your pharmacy taking up space.  Outdate Rx is happy to offer our Indated Medication processing.  Simply send us your indated products with your normal returns.  We will do everything possible to get immediate credit for them and if they are still not presently returnable, we will hold them in our warehouse until they do become returnable.  </p>
                <p className="grey-text">If your pharmacy is closing or needs to liquidate product for cash flow, give us a call and we can discuss other options for getting immediate credit for your inventory.</p>
            </div>
        </div>
    </div>
)

export default Indates;