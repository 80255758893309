import React from 'react';
import { Switch, Route } from 'react-router-dom';
import About from './about';
import TermsAndConditions from './terms';

const AboutRouter = props => (
    <Switch>
        <Route exact path="/about" component={About} />
        <Route exact path="/about/terms" component={TermsAndConditions} />
    </Switch>
)

export default AboutRouter;