import React from 'react';
import { Link } from 'react-router-dom';
import man from '../assets/man.png';

class About extends React.Component {

    render = () => (
        <div>
            <div className="row subnav" style={{ display: 'flex', justifyContent: 'space-around' }}>
                <div style={{ display: 'flex', justifyContent: 'space-evenly', position: 'absolute', bottom: '4px', left: '80px', fontSize: '1.5em' }}>
                    <Link className="white-text" to="/">Home</Link>
                    <span className="grey-text text-darken-2" style={{ marginLeft: '4px', marginRight: '4px', fontWeight: '500' }}>/</span>
                    <span className="grey-text text-darken-2">About</span>
                </div>
            </div>
            <div className="row" style={{ marginBottom: '0px', padding: '0px' }}>
                <div className="col s12 l6 hide-on-med-and-down">
                    <img className="responsive-img center" src={man} alt="Typical ORx Employee" />
                </div>
                <div className="col s12 l4 offset-l1" style={{ marginTop: '80px' }}>
                    <div className="row">
                        <h3 className="center">Outdate Rx is changing expectations about how reverse distribution works</h3>
                        <p className="grey-text center">Contact us to try out our sevice today! With no commitments, there really is nothing to lose</p>
                    </div>
                    <div className="row">
                        <div className="col s6 offset-s3">
                            <Link to="/contacts" className="btn-large red white-text center col s12">Contact Us</Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row smarty-pants" style={{ marginBottom: '0px', position: 'relative', bottom: '42px' }}>
                <div className="row">
                    <h2 className="center" style={{ marginBottom: '6px' }}>MAKE A SMART CHOICE</h2>
                    <div className="row">
                        <div className="center" style={{ position: 'relative', left: 'CALC(50vw - 120px)', backgroundColor: 'red', width: '240px', height: '4px' }} />
                    </div>
                </div>
                <div className="row">
                    <div className="col s12 l3 center">
                        <div className="row">
                            <div className="red lighten-1" style={{ position: 'relative', height: '80px', width: '80px', borderRadius: '50%', display: 'inline-block' }}>
                                <i className="material-icons medium white-text" style={{ position: 'absolute', top: '10px', left: '10px' }}>schedule</i>
                            </div>
                        </div>
                        <div className="row">
                            <h5>Save Time</h5>
                            <p style={{ fontSize: '1.2em' }}>Super fast processing means less time to get your refunds</p>
                        </div>
                    </div>
                    <div className="col s12 l3 center">
                        <div className="row">
                            <div className="red lighten-1" style={{ position: 'relative', height: '80px', width: '80px', borderRadius: '50%', display: 'inline-block' }}>
                                <i className="fas fa-award white-text" style={{ position: 'absolute', top: '15px', left: '22px', fontSize: '48px' }} />
                            </div>
                        </div>
                        <div className="row">
                            <h5>Premium Service</h5>
                            <p style={{ fontSize: '1.2em' }}>Unmatched service means more returnable meds than anyone else</p>
                        </div>
                    </div>
                    <div className="col s12 l3 center">
                        <div className="row">
                            <div className="red lighten-1" style={{ position: 'relative', height: '80px', width: '80px', borderRadius: '50%', display: 'inline-block' }}>
                                <i className="fas fa-coins white-text" style={{ position: 'absolute', top: '15px', left: '15px', fontSize: '48px' }}></i>
                            </div>
                        </div>
                        <div className="row">
                            <h5>Cost Savings</h5>
                            <p style={{ fontSize: '1.2em' }}>More returns means more money in your pharmacy, where it belongs</p>
                        </div>
                    </div>
                    <div className="col s12 l3 center">
                        <div className="row">
                            <div className="red lighten-1" style={{ position: 'relative', height: '80px', width: '80px', borderRadius: '50%', display: 'inline-block' }}>
                                <i className="far fa-star white-text" style={{ position: 'absolute', top: '15px', left: '12px', fontSize: '48px' }}></i>
                            </div>
                        </div>
                        <div className="row">
                            <h5>The Right Choice</h5>
                            <p style={{ fontSize: '1.2em' }}>It all adds up to the best value in reverse distribution</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row" style={{ marginTop: '0px', position: 'relative', bottom: '78px' }}>
                <div className="row red lighten-2">
                    <h2 className="center white-text" style={{ marginBottom: '6px' }}>OUR STORY</h2>
                    <div className="row">
                        <div className="center" style={{ position: 'relative', left: 'CALC(50vw - 65px)', backgroundColor: 'white', width: '110px', height: '4px' }} />
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <p className="grey-text">Outdate Rx was founded in 2014 with the intent to revolutionize the way reverse distribution worked.  Our founders had extensive experience in logistics and reverse distribution and saw the limitations that frustrated pharmacies and hospitals across the country.  With that knowledge, they went to work solving the problems of the batch return method that plague every other reverse distributor.</p>
                    </div>
                    <div className="row">
                        <p className="grey-text">Outdate Rx is the nation’s only reverse distributor to utilize the Isoturns return method.  Under our unique system, each and every medication you return will be processed, returned and credited individually.  This ensures that your return credits are exactly what the manufacturer pays on your returned products.  Because your returns are never mixed with those of other pharmacies, we are able to show you the credit memos that are received from each manufacturer for your medications to prove accuracy.  This unique process enables us to have more accurate reports, to decrease the time from when you send us your returns to when you receive payment and to ensure that you always get exactly the credit you are entitled.</p>
                    </div>
                    <div className="row">
                        <p className="grey-text">Outdate Rx is headquartered in Redlands, California.With service representatives across the country, Outdate Rx offers both on-site and mail-in service nationwide. We have a subsidiary, Recall Returns, that provides processing and packing service for all recalled products, pharmaceutical, medical, and otherwise. We also have partnerships with a variety of vendors that allow our customers to use their return credits to purchase a wide array of goods and services.</p>
                    </div>
                    <div className="row">
                        <p className="grey-text">Outdate Rx has been rapidly growing since its founding and now boasts agreements with many large groups and IDN’s across the nation.  Our understanding of what pharmacies need along with our unique processes and our commitment to excellent service have separated us from other reverse distributors.  Outdate Rx is committed to being the company you can turn to for all of your reverse distribution needs.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About;