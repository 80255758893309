import React from 'react';
import { Switch, Route } from 'react-router-dom';
import OnSite from './onsite';
import Indates from './indates';
import MailIn from './mailin';
import Controls from './controls';
import Recalls from './recalls';
import Inventory from './inventory';
import Pricing from './pricing';
import Services from './services';

const ServicesRouter = props => (
    <Switch>
        <Route exact path="/services" component={Services} />
        <Route exact path="/services/onsite" component={OnSite} />
        <Route exact path="/services/indates" component={Indates} />
        <Route exact path="/services/mail" component={MailIn} />
        <Route exact path="/services/controls" component={Controls} />
        <Route exact path="/services/recalls" component={Recalls} />
        <Route exact path="/services/inventory" component={Inventory} />
        <Route exact path="/services/pricing" component={Pricing} />
    </Switch>
)

export default ServicesRouter;