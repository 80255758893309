import Header from './layout/header';
import { Route, Switch, BrowserRouter as Router } from 'react-router-dom';
import Home from './home';
import AboutRouter from './about/aboutRouter';
import Footer from './layout/footer';
import ServicesRouter from './services/servicesRouter';
import LACRouter from './licensingAndCompliance/lacRouter';
import ContactRouter from './contact/contactRouter';
import ArticleRouter from './articles/articleRouter';
import PageNotFound from './pageNotFound';

function App() {
  return (
    <Router>
      <Header />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/about" component={AboutRouter} />
        <Route path="/services" component={ServicesRouter} />
        <Route path="/license" component={LACRouter} />
        <Route path="/contacts" component={ContactRouter} />
        <Route path="/articles" component={ArticleRouter} />
        <Route path="**" component={PageNotFound} />
      </Switch>
      <Footer />
    </Router>
  );
}

export default App;
