import React from 'react';
import { Link } from 'react-router-dom';
import recallsImg from '../assets/recalls.jpg'

const Recalls = props => (
    <div>
        <div className="row subnav" style={{ display: 'flex', justifyContent: 'space-around' }}>
            <div style={{ display: 'flex', justifyContent: 'space-evenly', position: 'absolute', bottom: '4px', left: '80px', fontSize: '1.5em' }}>
                <Link className="white-text" to="/">Home</Link>
                <span className="grey-text text-darken-2" style={{ marginLeft: '4px', marginRight: '4px', fontWeight: '500' }}>/</span>
                <Link className="white-text" to="/services">Services</Link>
                <span className="grey-text text-darken-2" style={{ marginLeft: '4px', marginRight: '4px', fontWeight: '500' }}>/</span>
                <span className="grey-text text-darken-2">Recalls</span>
            </div>
        </div>
        <div className="container">
            <div className="row">
                <h4>Recalls</h4>
                <div className="row">
                    <img className="responsive-img" src={recallsImg} alt="On-Site" />
                </div>
            </div>
            <div className="row">
                <h5>Service Overview</h5>
            </div>
            <div className="row">
                <p className="grey-text">Recalls are a headache that no one wants to deal with so we’ve made the process simple for you. Simply attach a copy of the recall notice to your recalled product and place it in with your normal returns. We’ll take care of the rest.</p>
                <p className="grey-text">If you are looking for ways to stay in compliance, we have solutions for that as well. We are excited to announce that NRAC (The National Recall Alert Center) has partnered with us to offer a special rate for their premium recall alert system.</p>
                <p className="grey-text">Serving 4 out of 5 hospitals nationwide since 1973, NRAC is the nation’s premier recall notification system. They offer the nations only real time 24/7/365 recall alert system and have been the leader in ensuring patient safety and recall compliance for over 45 years.</p>
                <p className="grey-text">Contact us today to learn more about the special offer they have for Outdate Rx customers.</p>
            </div>
        </div>
    </div>
)

export default Recalls;