import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Lincenses from './lincenses';

const LACRouter = props => (
    <Switch>
        <Route exact path="/license" component={Lincenses} />
    </Switch>
)

export default LACRouter;