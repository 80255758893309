import React from 'react';
import { Link } from 'react-router-dom';

const TermsAndConditions = props => (
    <div >
        <div className="row subnav" style={{ display: 'flex', justifyContent: 'space-around' }}>
            <div style={{ display: 'flex', justifyContent: 'space-evenly', position: 'absolute', bottom: '4px', left: '80px', fontSize: '1.5em' }}>
                <Link className="white-text" to="/">Home</Link>
                <span className="grey-text text-darken-2" style={{ marginLeft: '4px', marginRight: '4px', fontWeight: '500' }}>/</span>
                <Link className="white-text" to="/about">About</Link>
                <span className="grey-text text-darken-2" style={{ marginLeft: '4px', marginRight: '4px', fontWeight: '500' }}>/</span>
                <span className="grey-text text-darken-2">Terms And Conditions</span>
            </div>
        </div>
        <div className="container">
            <div className="row">
                <h6><span className="red-text">01.</span> Eligible Products</h6>
                <p className="grey-text">Outdate Rx accepts opened or unopened packages of prescription, and Schedule CII-CV medications in original containers which have not been previously dispensed to patients or customers and have a reasonable expectation of credit from the manufacturer. Schedule CII-CV medications refer to the drugs and substances defined in 21 CFR 1308.</p>
                <p className="grey-text">Outdate Rx cannot accept returned products or materials which have been previously sorted and identified as non-returnable or as pharmaceutical waste. Outdate Rx does not accept bulk chemicals or any product which is clearly identifiable as being designated as non-returnable by the original manufacturer. Outdate Rx does not accept products or medications that are classified as hazardous waste (RCRA) medications. Outdate Rx reserves the right to refuse any shipment which does not conform to its policies or these terms and conditions.</p>
                <p className="grey-text">By sending products to Outdate Rx customer represents and warrants that all returned products shipped to Outdate Rx will comply with all federal, state, and local laws, including the Drug Enforcement Agency and Environmental Protection Agency. The customer also warrants that all products were obtained through legal channels and are not counterfeit, adulterated, or otherwise illegally obtained.</p>
            </div>
            <div className="row">
                <h6><span className="red-text">02.</span> Recalled Products</h6>
                <p className="grey-text">Manufacturer and wholesaler recalled products may be returned to Outdate Rx for processing. Outdate Rx will use all reasonable efforts to comply with the recall requirements and obtain credit for the customer. Outdate Rx will process all other items as a standard return using normal return policies and procedures.</p>
            </div>
            <div className="row">
                <h6><span className="red-text">03.</span> Controlled Medications / Schedule CII-CV</h6>
                <p className="grey-text">Outdate Rx will accept CII-CV medications for return from customers. All controlled medications will be counted and verified by Outdate Rx. Outdate Rx’s counts of these medications will be deemed final.</p>
                <p className="grey-text">Prior to accepting any controlled medications, the customer agrees to provide Outdate Rx with a copy of its current DEA registration forms.</p>
                <p className="grey-text">Outdate Rx will provide executed 222 forms and tamper-evident packaging for shipping of all CII products. Only those items listed on the 222 may be sent to Outdate Rx. Products may only be shipped after the receipt of the 222 form.</p>
            </div>
            <div className="row">
                <h6><span className="red-text">04.</span> Ineligible Products</h6>
                <p className="grey-text">Any products that do not qualify for a return under normal manufacturer policies will be deemed non-returnable. A returned product may, due to changed policy or other events become ineligible for return and become deemed non-returnable or pharmaceutical waste.</p>
                <p className="grey-text">All products that are identified on the EPA List of Hazardous Wastes, including characteristic and listed products, compounds, and medications may not be sent to Outdate Rx under any circumstances. Additionally, products identified as ineligible under Subpart P regulations, including OTCs, compounded products, partial vials, opened liquids and creams, and other products with no expectation of manufacturer credit cannot be sent to Outdate Rx.</p>
                <p className="grey-text">In the event the manufacturer closes its facilities or undergoes a shutdown or other lapse in business, those products which have yet to receive credit may be changed from returnable to non-returnable according to the manufacturer’s policy changes.</p>
                <p className="grey-text">Once a product is designated non-returnable it shall be designated pharmaceutical waste. The customer authorizes Outdate Rx to dispose of such products as it sees fit according to applicable laws and regulations. The customer shall have no claim on products once they have been designated non-returnable. Outdate Rx will provide a disposition summary to the customer explaining methods of disposal at the customer’s request.</p>
            </div>
            <div className="row">
                <h6><span className="red-text">05.</span> Shipping Costs</h6>
                <p className="grey-text">The customer agrees that for on-site service offerings all normal shipping costs will be covered by Outdate Rx, provided the customer uses the systems and programs provided by Outdate Rx. These programs require that no package have any dimension greater than 18″. Any package with dimensions greater than 18" may incur additional charges or be refused at the discretion of Outdate Rx. The customer agrees for Self Service Mail-in service offerings, the customer will be responsible for all shipping charges. Outdate Rx will not be liable for any shipping charges incurred by the customer which were not pre-authorized in writing by Outdate Rx. Outdate Rx agrees to cover all shipping costs from Outdate Rx to the products' original manufacturers on the customer’s behalf.</p>
            </div>
            <div className="row">
                <h6><span className="red-text">06.</span> Service Fees</h6>
                <p className="grey-text">The customer agrees to pay Outdate Rx a service fee equal to a percentage of the Published Value of the Returnable Medications or a minimum service fee, where applicable. Service Fees are calculated at the time of original inventory by Outdate Rx. Service fees may be adjusted if errors are found. Published values are obtained by Outdate Rx through a third-party vendor and may be reviewed by calling Outdate Rx.</p>
                <p className="grey-text">Additional charges may apply to your service including shipping costs, destruction costs, and other charges.</p>
                <p className="grey-text">The original wholesaler of record may elect to add a service fee to returns processed by Outdate Rx. This service fee will be deducted from the actual credits received.</p>
            </div>
            <div className="row">
                <h6><span className="red-text">07.</span> Title, Risk of Loss</h6>
                <p className="grey-text">The customer retains title to all returned products until they have been received by the applicable manufacturer or designated non-returnable waste and destroyed by Outdate Rx. Title to non-returnable products, including scheduled medications, will transfer to Outdate Rx at the time the products are designated non-returnable waste. Outdate Rx shall not be liable for any damages or losses incurred during the preparation, shipping, or processing of products for return. Outdate Rx assumes no liability for any delay that results directly or indirectly from causes outside the control of Outdate Rx or without the fault of Outdate Rx including the potential loss of credit value, except as otherwise expressly provided herein.</p>
            </div>
            <div className="row">
                <h6><span className="red-text">08.</span> Return Goods Authorization</h6>
                <p className="grey-text">Certain manufacturers require a Return Goods Authorization to be obtained prior to shipping products back to them. If a manufacturer denies this authorization for any reason, the associated product will be deemed non-returnable and will be destroyed according to Outdate Rx’s policies and procedures.</p>
            </div>
            <div className="row">
                <h6><span className="red-text">09.</span> Indated Products</h6>
                <p className="grey-text">Non-expired products shipped to Outdate Rx which are not immediately creditable according to manufacturer policies but are believed to have potential future credit value may be held at the discretion of Outdate Rx. Products which have expected credit value within less than 90 days shall be subject to normal return service fee rates. Products that expire greater than 90 days from the date of determination may be held for future date processing and will be subject to a storage and double processing fee.</p>
                <p className="grey-text">Outdate Rx reserves the right, at its sole discretion, to salvage, dispose of, remit, and/ or destroy any product it believes to not be in an immediately creditable state without a claim for remuneration, including potentially creditable scheduled medications. Outdate Rx reserves the right to immediately remunerate potentially creditable products in lieu of holding and managing such products.</p>
            </div>
            <div className="row">
                <h6><span className="red-text">10.</span> Pricing</h6>
                <p className="grey-text">The customer is responsible for notifying Outdate Rx in writing of any possible pricing discrepancies. Including, but not limited to 1) Products purchased directly from the manufacturer at a special or negotiated rate different from the standard wholesaler acquisition cost or 2) products covered by Section 340b of the Public health services act, or 3) Products purchased through a secondary wholesale market, or 4) product purchased outside normal distribution channels, or 5) stockpiled or donated products.</p>
                <p className="grey-text">The customer acknowledges and agrees that these special-priced products cannot be mixed with regular products. Outdate Rx will not be liable for any errors or discrepancies due to errors in connection with these different product values. Service fees earned by Outdate Rx may be retained even if it is later determined that the values were based on alternate pricing.</p>
                <p className="grey-text">Outdate Rx reserves the right to require proof of purchase. If no proof of purchase can be produced, the product may be deemed non-returnable and be subject to destruction. Outdate Rx will not reimburse the customer in the event a manufacturer rejects a return for any reason.
                </p>
            </div>
            <div className="row">
                <h6><span className="red-text">11.</span> Direct Credits</h6>
                <p className="grey-text">Certain manufacturers elect to issue credits only to the customer via check or direct wholesale account credit. Outdate Rx will facilitate these returns to maximize credits to the customer. These returns are noted on the customer’s credit statement. The customer will notify Outdate Rx in writing of any payments directly received from manufacturers for products processed by Outdate Rx. This information may be emailed, sent via the Outdate Rx website at www.outdaterx.com, or mailed directly to Outdate Rx. Service fees for directly credited products are added to the total account service fees.</p>
            </div>
            <div className="row">
                <h6><span className="red-text">12.</span> Credit and Funds Disbursement</h6>
                <p className="grey-text">Outdate Rx will work with the customer’s wholesaler to facilitate the disbursement of checks or credits on a monthly basis. Return cycles will vary based on specific customer, manufacturer, and wholesaler details. Some manufacturers will only allow for wholesale account credit and not consolidated checks via Outdate Rx. Please call to discuss terms and options for your specific account. Credits issued will be the total accumulated credits less any Outdate Rx fees and wholesaler fees. Outdate Rx service fees are due immediately and will be taken from the account balance prior to any credits being issued to the customer.</p>
            </div>
            <div className="row">
                <h6><span className="red-text">13.</span> Rights to Credit</h6>
                <p className="grey-text">The customer assigns all of its rights and interest to any credits due for returned products processed by Outdate Rx and authorizes Outdate Rx to pursue such credits from manufacturers and wholesalers.</p>
            </div>
            <div className="row">
                <h6><span className="red-text">14.</span> Product Integrity</h6>
                <p className="grey-text">Outdate Rx shall not be liable for the value of and cannot guarantee credit for products. This includes but is not limited to products not purchased directly from a manufacturer or approved wholesaler, products purchased outside the United States, and counterfeit items.</p>
                <p className="grey-text">Outdate Rx will not be liable for product value or fees in the event manufacturers or wholesalers do not respond in a timely fashion or do not comply with Outdate Rx’s requests or policies when submitting return authorization requests, crediting or form requests for Schedule drugs and/ or other requests that help facilitate the processing and crediting of your returned products.</p>
            </div>
            <div className="row">
                <h6><span className="red-text">15.</span> Changes in Creditablility Status</h6>
                <p className="grey-text">Because of the complex nature of processing pharmaceutical products for return, the customer agrees it is not possible for Outdate Rx to be aware of all circumstances that may affect the credit value or the issuing of credit. The customer agrees and acknowledges that Outdate Rx will not be liable for any damages, losses, or diminishment of credit value resulting from a change in circumstances. Outdate Rx will not be liable for any changes including but not limited to credit reversals, overpayment discoveries, and any other change in credit status. The customer agrees that Outdate Rx will retain any payment earned regardless of future changes in credit status.</p>
                <p className="grey-text">Other types of changes in credit status could mean, but are not limited to:</p>
                <p className="grey-text">1) The manufacturer claims it did not receive the product</p>
                <p className="grey-text">2) Manufacturer disputes the quantities</p>
                <p className="grey-text">3) Manufacturer changes returnability status</p>
                <p className="grey-text">4) Returned product subsequently becomes subject to recall and the manufacturer refuses to issue credit as a result</p>
                <p className="grey-text">5) Material change to the pricing</p>
                <p className="grey-text">6) Manufacturer issues a lower credit value than expected</p>
                <p className="grey-text">7) Manufacturers or wholesalers become insolvent or bankrupt or file for bankruptcy protection</p>
                <p className="grey-text">8) A manufacturer may enter a debit balance with the wholesaler</p>
                <p className="grey-text">9) A manufacturer decides to partially pay any credit invoice</p>
                <p className="grey-text">10) A manufacturer withdraws previously issued credits for any reason</p>
                <p className="grey-text">11) Wholesalers may terminate or alter their relationship with Outdate Rx</p>
            </div>
            <div className="row">
                <h6><span className="red-text">16.</span> Invoices</h6>
                <p className="grey-text">Whenever possible the service fees owed to Outdate Rx will be deducted from the actual return credits. The customer agrees that other charges imposed by a wholesaler or manufacturer, (these “Third Party Charges” may include but are not limited to handling fees, drop ship fees, processing fees, and other charges) may be paid in like manner through the actual return credits. However, if the fees cannot be deducted from the actual credits then an invoice will be sent to the customer for payment. Payment for such invoices is due within 15 days from receipt of the invoice by the customer. At its sole discretion, Outdate Rx may offset the balance against the value of any present or future credits.</p>
            </div>
            <div className="row">
                <h6><span className="red-text">17.</span> Term and Termination</h6>
                <p className="grey-text">There is no term for Outdate Rx services and customers may elect to terminate service at any time. Upon termination of the service, Outdate Rx will continue to provide services according to the terms set forth in this agreement for any products received prior to the termination. Outdate Rx will have no obligation to continue to provide services for more than 12 months after the termination date and the customer will not be entitled to receive credit for products sent to Outdate Rx after this window.</p>
                <p className="grey-text">In the event the customer terminates its relationship with a Wholesaler and does not enter into a working relationship with an Outdate Rx-approved wholesaler, the customer shall notify Outdate Rx and may immediately terminate these terms and conditions.</p>
            </div>
            <div className="row">
                <h6><span className="red-text">18.</span> Modification</h6>
                <p className="grey-text">Any changes to these terms and conditions must be made in writing and mutually agreed upon.</p>
            </div>
            <div className="row">
                <h6><span className="red-text">19.</span> Limitation of Liability</h6>
                <p className="grey-text">Outdate Rx’s liability, whether in contract, tort, under any warranty, in negligence, or otherwise, shall not exceed five hundred US dollars. Outdate Rx makes no warranty, expressly or implied, and in no event shall Outdate Rx be liable for any direct, indirect, consequential, or special damages.</p>
            </div>
            <div className="row">
                <h6><span className="red-text">20.</span> Force Majeure</h6>
                <p className="grey-text">Outdate Rx will be excused from performing its obligations under any service for extraordinary events, including acts of God, fires, earthquakes, terrorism, strikes, labor disputes, acts of war, civil unrest, the acts or intervention of any governmental or regulatory authority, material changes in regulations, solutions performance, or safety requirements, reasonable and necessary manufacturing adjustments, slowdowns and stoppages, facility changes, and modifications. Outdate Rx will be excused from performance for the duration of such events.</p>
            </div>
            <div className="row">
                <h6><span className="red-text">21.</span> Indemnification</h6>
                <p className="grey-text">Each party agrees to hold harmless and indemnify and defend the other party from and against any and all liabilities, claims, penalties, forfeitures, suit, and the costs and expenses incident thereto which either party may hereafter incur, become responsible for, or pay as a result of death or bodily injury, the environment or any violation of governmental laws, regulations, and orders or any negligent or willful act or omission of either party, its employees or subcontractors in the performance of these terms and conditions.</p>
            </div>
            <div className="row">
                <h6><span className="red-text">22.</span> Governing Law</h6>
                <p className="grey-text">All disputes shall be governed by the laws of the state of California, without regard to the rules regarding conflict of law.</p>
            </div>
        </div>
    </div>
)

export default TermsAndConditions;