import React from 'react';
import { Link } from 'react-router-dom';
import inventoryImg from '../assets/inventory.jpg'

const Inventory = props => (
    <div>
        <div className="row subnav" style={{ display: 'flex', justifyContent: 'space-around' }}>
            <div style={{ display: 'flex', justifyContent: 'space-evenly', position: 'absolute', bottom: '4px', left: '80px', fontSize: '1.5em' }}>
                <Link className="white-text" to="/">Home</Link>
                <span className="grey-text text-darken-2" style={{ marginLeft: '4px', marginRight: '4px', fontWeight: '500' }}>/</span>
                <Link className="white-text" to="/services">Services</Link>
                <span className="grey-text text-darken-2" style={{ marginLeft: '4px', marginRight: '4px', fontWeight: '500' }}>/</span>
                <span className="grey-text text-darken-2">Inventory</span>
            </div>
        </div>
        <div className="container">
            <div className="row">
                <h4>Inventory</h4>
                <div className="row">
                    <img className="responsive-img" src={inventoryImg} alt="Inventory" />
                </div>
            </div>
            <div className="row">
                <h5>Service Overview</h5>
            </div>
            <div className="row">
                <p className="grey-text">Outdate Rx is pleased to offer you the opportunity to pay for your inventory services using your return credits. </p>
                <p className="grey-text">Utilize our network of partners and not only will you have access to the top notch services, but you’ll also get a discount on the normal rates. Save time and money by using the Outdate Rx preferred network.</p>
            </div>
        </div>
    </div>
)

export default Inventory;