import React from 'react';
import logo from '../assets/logo.jpg'
import M from 'materialize-css';
import { Link } from 'react-router-dom';

class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = { path: '' }
    }

    componentDidMount = () => {
        M.Dropdown.init(document.querySelectorAll('.dropdown-trigger'), { hover: true })
        M.Sidenav.init(document.querySelectorAll('.sidenav'));
        M.Collapsible.init(document.querySelectorAll('.collapsible'))
    }

    render = () => (
        <div style={{ position: 'relative' }}>
            <div className="row black grey-text text-lighten-2" style={{ display: 'flex', marginBottom: '0px' }}>
                <div style={{ display: 'flex', marginLeft: '20px' }}>
                    <i className="material-icons" style={{ marginRight: '8px' }}>call</i>
                    <span>Call: (855) 688-3283</span>
                </div>
                <div style={{ display: 'flex', marginLeft: '60px' }}>
                    <i className="material-icons" style={{ marginRight: '8px' }}>email</i>
                    <span>sales@outdaterx.com</span>
                </div>
            </div>
            <ul id="about-dropdown" className="dropdown-content">
                <li><Link style={{ color: '#444' }} to="/about">About</Link></li>
                <li><Link style={{ color: '#444' }} to="/about/terms">Terms and Conditions</Link></li>
            </ul>
            <ul id="services-dropdown" className="dropdown-content">
                <li><Link style={{ color: '#444' }} to="/services">Services</Link></li>
                <li><Link style={{ color: '#444' }} to="/services/onsite">On-Site</Link></li>
                <li><Link style={{ color: '#444' }} to="/services/mail">Mail In</Link></li>
                <li><Link style={{ color: '#444' }} to="/services/indates">Indates</Link></li>
                <li><Link style={{ color: '#444' }} to="/services/controls">Controlled Substances</Link></li>
                <li><Link style={{ color: '#444' }} to="/services/recalls">Recalls</Link></li>
                <li><Link style={{ color: '#444' }} to="/services/inventory">Inventory</Link></li>
                <li><Link style={{ color: '#444' }} to="/services/pricing">Service Option Pricing Table</Link></li>
            </ul>
            <nav style={{ boxShadow: 'none', height: '80px' }} className="hide-on-med-and-down white">
                <div className="nav-wrapper white" style={{ paddingLeft: '2vw', paddingRight: '6vw' }}>
                    <Link to="/" className="brand-logo"><img style={{ height: '80px', width: 'auto' }} className="responsive-img" src={logo} alt="Outdate Pill Logo" /></Link>
                    <ul id="nav-items" className="right">
                        <li>
                            <Link style={{ fontSize: '1.3em', color: '#444' }} to="/about" data-target="about-dropdown" className="dropdown-trigger">About<i className="material-icons right">arrow_drop_down</i></Link>
                        </li>
                        <li>
                            <Link style={{ fontSize: '1.3em', color: '#444' }} to="/services" data-target="services-dropdown" className="dropdown-trigger">Services<i className="material-icons right">arrow_drop_down</i></Link>
                        </li>
                        <li><Link style={{ fontSize: '1.3em', color: '#444' }} to="/license">Licensing and Compliance</Link></li>
                        <li><Link style={{ fontSize: '1.3em', color: '#444' }} to="/contacts">Contacts</Link></li>
                        <li><Link style={{ fontSize: '1.3em', color: '#444' }} to="/articles">Articles</Link></li>
                        <li><a style={{ fontSize: '1.3em', color: '#444' }} href="http://reports.outdaterx.com">User Portal</a></li>
                    </ul>
                </div>
            </nav>
            <ul id="slide-out" className="sidenav">
                <li>
                    <div className="user-view" style={{ height: '80px' }}>
                        <div className="background">
                            <img src={logo} alt="Outdate Pill Logo" />
                        </div>
                    </div>
                </li>
                <li><Link to="/">Home</Link></li>
                <li className="no-padding">
                    <ul className="collapsible collapsible-accordion">
                        <li>
                            <a className="collapsible-header" href="#!">About<i className="material-icons">arrow_drop_down</i></a>
                            <div className="collapsible-body">
                                <ul>
                                    <li><Link style={{ color: '#444' }} to="/about">About</Link></li>
                                    <li><Link style={{ color: '#444' }} to="/about/terms">Terms and Conditions</Link></li>
                                </ul>
                            </div>
                        </li>
                    </ul>
                </li>
                <li className="no-padding">
                    <ul className="collapsible collapsible-accordion">
                        <li>
                            <a className="collapsible-header" href="#!">Services<i className="material-icons">arrow_drop_down</i></a>
                            <div className="collapsible-body">
                                <ul>
                                    <li><Link style={{ color: '#444' }} to="/services">Services</Link></li>
                                    <li><Link style={{ color: '#444' }} to="/services/onsite">On-Site</Link></li>
                                    <li><Link style={{ color: '#444' }} to="/services/mail">Mail In</Link></li>
                                    <li><Link style={{ color: '#444' }} to="/services/indates">Indates</Link></li>
                                    <li><Link style={{ color: '#444' }} to="/services/controls">Controlled Substances</Link></li>
                                    <li><Link style={{ color: '#444' }} to="/services/recalls">Recalls</Link></li>
                                    <li><Link style={{ color: '#444' }} to="/services/inventory">Inventory</Link></li>
                                    <li><Link style={{ color: '#444' }} to="/services/pricing">Service Option Pricing Table</Link></li>
                                </ul>
                            </div>
                        </li>
                    </ul>
                </li>
                <li><Link to="/license">Licensing and Compliance</Link></li>
                <li><Link to="/contacts">Contacts</Link></li>
                <li><Link to="/articles">Articles</Link></li>
                <li><a href="http://reports.outdaterx.com">User Portal</a></li>
            </ul>
            <a href="#!" data-target="slide-out" className="sidenav-trigger hide-on-large-only"><i className="material-icons black-text">menu</i></a>
        </div>
    )
}

export default Header;
