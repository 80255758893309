import React from 'react';
import M from 'materialize-css';
import { Link } from 'react-router-dom';
import carImage from './assets/car.jpg';
import Axios from 'axios';

class Home extends React.Component {
    constructor(props) {
        super(props);
        this.interval = null;
        this.width = window.innerWidth;
        this.state = this.initState();
    }

    initState = () => ({
        sendSuccess: false,
        sending: false,
        errMessage: '',
        arbitrary: true,
        values: {
            firstName: '',
            lastName: '',
            company: '',
            email: '',
            msg: ''
        }
    })

    onChange = e => this.setState(p => ({ values: { ...p.values, [e.target.id]: e.target.value }, errMessage: '' }))

    componentDidMount = () => {
        M.Carousel.init(document.querySelectorAll('.carousel'), {
            onCycleTo: this.setTimer
        });

        window.addEventListener('resize', this.resizeListener);
    }

    setTimer = () => {
        if (this.interval)
            clearTimeout(this.interval);

        this.interval = setTimeout(() => {
            M.Carousel.getInstance(document.querySelector('#car')).next();
        }, 10000);
    }

    componentWillUnmount = () => {
        document.querySelectorAll('.carousel').forEach(el => {
            const c = M.Carousel.getInstance(el);
            if (c) { c.destroy() };
        })

        window.removeEventListener('resize', this.resizeListener)
        clearTimeout(this.interval);
    }

    resizeListener = e => {
        if (Math.abs(this.width - window.innerWidth) > 100) {
            this.width = window.innerWidth;

            document.querySelectorAll('.carousel').forEach(el => {
                const c = M.Carousel.getInstance(el)
                if (c) { c.destroy() }
            })

            this.setState(p => ({ arbitrary: !p.arbitrary }), () => {
                M.Carousel.init(document.querySelectorAll('.carousel'), {
                    onCycleTo: this.setTimer
                })
            })
        }
    }

    sendMessage = e => {
        e.preventDefault();

        if (!this.state.values.firstName)
            return this.setState({ errMessage: 'First name must not be blank' });

        if (!this.state.values.email)
            return this.setState({ errMessage: 'Email must not blank' })

        this.setState({ sending: true }, () => {
            Axios.post('/api/v1/inforequest', this.state.values)
                .then(result => this.setState({ sendSuccess: true }))
                .catch(err => {
                    if (err.response)
                        this.setState({ errMsg: err.resonse.data, sendSuccess: false })
                    else
                        console.log(err)
                })
                .finally(() => this.setState({ sending: false }))
        })
    }

    getCarousel = () => {
        if (window.innerWidth > 680)
            return (
                <div id="car" className="carousel carousel-slider center" style={{ minHeight: '800px' }}>
                    <div className="carousel-item red white-text" href="#one!" style={{ backgroundImage: 'url(/assets/slide-1.jpg)' }}>
                        <div className="container">
                            <div className="row" style={{ marginTop: '120px' }}>
                                <div className="col s12 l8 offset-l2">
                                    <h1 className="white-text center" style={{ fontWeight: '500' }}>Make <span style={{ fontSize: '1.3em' }}>Your Pharmacy</span> Prosper With Our Solutions</h1>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col s12 l8 offset-l2">
                                    <p className="white-text center" style={{ fontSize: '1.3em' }}>Isoturns not only saves you money, it makes more medications returnable</p>
                                    <p className="white-text center" style={{ fontSize: '1.3em' }}>which means more credits other reverse distributors throw away.</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col s12 m4 offset-m4 l2 offset-l5">
                                    <Link to="/about" className="btn-large red white-text waves-effect waves-light">Our Company</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="carousel-item red white-text" href="#two!" style={{ backgroundImage: 'url(/assets/slide-2.jpg)' }}>
                        <div className="container">
                            <div className="row" style={{ marginTop: '120px' }}>
                                <div className="col s12 l8 offset-l2">
                                    <h1 className="white-text center" style={{ fontWeight: '500' }}>There's a <span style={{ fontSize: '1.3em' }}>Change Coming</span> to Reverse Distribution</h1>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col s12 l10 offset-l1">
                                    <p className="white-text center" style={{ fontSize: '1.3em' }}>Isoturns is changing the way returns are done.</p>
                                    <p className="white-text center" style={{ fontSize: '1.3em' }}>Faster Processing, More Returnable Medications, Better Reports and More!</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col s12 m4 offset-m4 l2 offset-l5">
                                    <Link to="/articles/isoturns" className="btn-large red white-text waves-effect waves-light">Learn More</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="carousel-item red white-text" href="#three!" style={{ backgroundImage: 'url(/assets/slide-3.jpg)' }}>
                        <div className="container">
                            <div className="row" style={{ marginTop: '120px' }}>
                                <div className="col s12 l8 offset-l2">
                                    <h1 className="white-text center" style={{ fontWeight: '500' }}>Trust <span style={{ fontSize: '1.3em' }}>Outdate Rx</span> to care for your returns</h1>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col s12 l8 offset-l2">
                                    <p className="white-text center" style={{ fontSize: '1.3em' }}>Trust our team to treat your returns like you would.</p>
                                    <p className="white-text center" style={{ fontSize: '1.3em' }}>Easy to read reports, straightforward pricing and more ensure that</p>
                                    <p className="white-text center" style={{ fontSize: '1.3em' }}>you can have confidence in our service.</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col s12 m4 offset-m4 l2 offset-l5">
                                    <Link to="/services" className="btn-large red white-text waves-effect waves-light">Our Company</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        else
            return (
                <div id="car" className="carousel carousel-slider center" style={{ minHeight: '520px' }}>
                    <div className="carousel-item blue-grey white-text" href="#one!">
                        <div className="container">
                            <div className="row" >
                                <div className="col s12 l8 offset-l2">
                                    <h3 className="white-text center">Make Your Pharmacy Prosper With Our Solutions</h3>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col s12 l8 offset-l2">
                                    <p className="white-text center" style={{ fontSize: '1.3em' }}>Isoturns not only saves you money, it makes more medications returnable</p>
                                    <p className="white-text center" style={{ fontSize: '1.3em' }}>which means more credits other reverse distributors throw away.</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col s12 m4 offset-m4 l2 offset-l5">
                                    <Link to="/about" className="btn-large red white-text waves-effect waves-light">Our Company</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="carousel-item blue white-text" href="#two!">
                        <div className="container">
                            <div className="row">
                                <div className="col s12 l8 offset-l2">
                                    <h3 className="white-text center">There's a Change Coming to Reverse Distribution</h3>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col s12 l10 offset-l1">
                                    <p className="white-text center" style={{ fontSize: '1.3em' }}>Isoturns is changing the way returns are done.</p>
                                    <p className="white-text center" style={{ fontSize: '1.3em' }}>Faster Processing, More Returnable Medications, Better Reports and More!</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col s12 m4 offset-m4 l2 offset-l5">
                                    <Link to="/articles/isoturns" className="btn-large red white-text waves-effect waves-light">Learn More</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="carousel-item teal darken-3 white-text" href="#three!">
                        <div className="container">
                            <div className="row">
                                <div className="col s12 l8 offset-l2">
                                    <h3 className="white-text center">Trust Outdate Rx to care for your returns</h3>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col s12 l8 offset-l2">
                                    <p className="white-text center" style={{ fontSize: '1.3em' }}>Trust our team to treat your returns like you would.</p>
                                    <p className="white-text center" style={{ fontSize: '1.3em' }}>Easy to read reports, straightforward pricing and more ensure that</p>
                                    <p className="white-text center" style={{ fontSize: '1.3em' }}>you can have confidence in our service.</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col s12 m4 offset-m4 l2 offset-l5">
                                    <Link to="/services" className="btn-large red white-text waves-effect waves-light">Our Company</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
    }

    render = () => (
        <div>
            {this.getCarousel()}
            <div className="row" style={{ marginTop: '40px' }}>
                <div className="container">
                    <div className="col s12 l4">
                        <div className="row">
                            <h3>Answering the big questions</h3>
                            <div className="red" style={{ width: '120px', height: '4px' }} />
                            <p>Why don't my credits match my reports?</p>
                            <p>Why does it take so long to get paid?</p>
                        </div>
                    </div>
                    <div className="col s12 l8">
                        <div style={{ minHeight: '240px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                            <p className="grey-text">For years pharmacies have been left wondering why their credits never match their reports and why it takes so long to get paid.  Other reverse distributors exploit these concerns and offer high rate return purchases.  In the end, however, the flaws are in their systems.  </p>
                            <p className="grey-text">At Outdate Rx we use our exclusive Isoturn process to ensure that your credits become your returns.  This means all your reports will always match.  Beyond that, we have the fastest turnaround in the industry, ensuring that your returns get to the manufacturer faster.  That means faster credits back to you!</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="red white-text" style={{ paddingTop: '120px', paddingBottom: '40px', marginBottom: '0px' }}>
                <div className="container">
                    <div className="row">
                        <div className="col s12 l4">
                            <div className="row" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                <i className="far fa-hand-pointer" style={{ fontSize: '60px' }} />
                                <h5 className="center">It all starts with you</h5>
                                <p className="center">We can assist you in identifying and removing expiring medications to ensure that no credits get left sitting on shelves.</p>
                            </div>
                        </div>
                        <div className="col s12 l4">
                            <div className="row" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                <i className="far fa-edit" style={{ fontSize: '60px' }} />
                                <h5 className="center">Reports you can use</h5>
                                <p className="center">All reports are emailed directly to you and are available 24/7 on our website. These reports assist with planning and inventory management</p>
                            </div>
                        </div>
                        <div className="col s12 l4">
                            <div className="row" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                <i className="fas fa-tachometer-alt" style={{ fontSize: '60px' }} />
                                <h5 className="center">We speed up the process</h5>
                                <p className="center">Your medications are processed and sent to the manufacturer in a matter of days. That means less time until you get paid.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="white-text" style={{ backgroundImage: 'url(/assets/handshake.jpg)', minHeight: '680px', marginTop: '0px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                <div className="container">
                    <div className="row">
                        <div className="col s12">
                            <h4 className="center" style={{ marginBottom: '4px' }}>It's all about peace of mind</h4>
                            <h1 className="center" style={{ fontWeight: '500' }}>Find out why Outdate Rx is the company you can trust</h1>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <div className="white" style={{ display: 'inline-block', width: '140px', height: '4px' }} />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col s12 m4 offset-m4 l2 offset-l5">
                            <Link to="/about" className="btn-large red white-text col s12" style={{ marginTop: '40px' }}>Read More</Link>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div className="container" style={{ minHeight: '600px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                    <div className="row">
                        <div className="col s12 l6">
                            <img className="responsive-img no-padding" src={carImage} alt="car with no wheels" />
                        </div>
                        <div className="col s12 l6 row">
                            <h3>We've got the solutions you need.</h3>
                            <div className="red" style={{ margin: '0px', padding: '0px', width: '120px', height: '4px' }} />
                            <p className="grey-text">You want a reliable service representative, fast credits and reports that make sense.  With Outdate Rx, you get that and more. </p>
                            <p className="grey-text">Learn about what separates us from other reverse distributors.  You might be surprised by how much Outdate Rx can do for you.</p>
                            <div className="col s12 m4">
                                <Link to="/articles/history" className="btn-large red white-text col s12">Learn More</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{ backgroundImage: 'url(/assets/slide-3.jpg)', minHeight: '600px' }}>
                <div className="container white-text">
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', minHeight: '600px' }}>
                        <div className="row">
                            <div className="col s12 l4">
                                <h3>Let the Number Speak for Us</h3>
                            </div>
                            <div className="col s12 l2">
                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                    <h5 className="center">We Have Clients in</h5>
                                    <h1 className="center" style={{ padding: '0px', margin: '0px', fontWeight: '600' }}>50</h1>
                                    <h5 className="center" >States</h5>
                                </div>
                            </div>
                            <div className="col s12 l2">
                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                    <h5 className="center">We've recoverd over</h5>
                                    <h1 className="center" style={{ padding: '0px', margin: '0px', fontWeight: '600' }}>80</h1>
                                    <h5 className="center" >Million</h5>
                                </div>
                            </div>
                            <div className="col s12 l2">
                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                    <h5 className="center">We process over</h5>
                                    <h1 className="center" style={{ padding: '0px', margin: '0px', fontWeight: '600' }}>700</h1>
                                    <h5 className="center" >Manufacturers</h5>
                                </div>
                            </div>
                            <div className="col s12 l2">
                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                    <h5 className="center">We've handled over</h5>
                                    <h1 className="center" style={{ padding: '0px', margin: '0px', fontWeight: '600' }}>100</h1>
                                    <h5 className="center" >Million Meds</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row" style={{ marginTop: '40px' }}>
                    <div className="video-container">
                        <iframe title="Outdate Rx" allowFullScreen={true} src="https://www.youtube.com/embed/rtS6putv0jw?feature=oembed&amp;start&amp;end&amp;wmode=opaque&amp;loop=0&amp;controls=1&amp;mute=0&amp;rel=0&amp;modestbranding=0"></iframe>
                    </div>
                </div>
            </div>
            <div style={{ backgroundImage: 'url(/assets/calculator.jpg)', position: 'relative', minHeight: '440px' }}>
                <div style={{ position: 'absolute', backgroundColor: '#00000068', top: '0px', left: '0px', minWidth: '100%', minHeight: '100%', zIndex: '1' }} />
                <div className="container">
                    <div className="row">
                        <div className="col s12 l3">
                            <div className="row">
                                <div style={{ position: 'relative', zIndex: 3, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', minHeight: '440px', minWidth: '100%' }}>
                                    <h2 className="center white-text">Request a call back</h2>
                                    <p className="white-text">Feel free to contact us with any questions you have.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col s12 l9">
                            {this.state.sendSuccess ?
                                <div style={{ zIndex: '3', position: 'relative', minHeight: '440px' }}>
                                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', minHeight: '440px' }}>
                                        <h4 className="center" style={{ color: '#ffffffff' }}>Your message has been sent. Our sales team will contact you soon!</h4>
                                    </div>
                                </div>
                                :
                                <form className="home-form" style={{ padding: '18px', position: 'relative', zIndex: '3' }}>
                                    <div className="row">
                                        <div className="input-field col s12 m6">
                                            <input className="white-text" id="firstName" type="text" onChange={this.onChange} value={this.state.values.firstName} />
                                            <label htmlFor="firstName">First Name</label>
                                        </div>
                                        <div className="input-field col s12 m6">
                                            <input id="lastName" type="text" onChange={this.onChange} value={this.state.values.lastName} />
                                            <label htmlFor="lastName">Last Name</label>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="input-field col s12 m6">
                                            <input id="company" type="text" onChange={this.onChange} value={this.state.values.company} />
                                            <label htmlFor="company">Company</label>
                                        </div>
                                        <div className="input-field col s12 m6">
                                            <input id="email" type="text" onChange={this.onChange} value={this.state.values.email} />
                                            <label htmlFor="email">Your Email</label>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="input-field col s12">
                                            <textarea id="msg" className="materialize-textarea" onChange={this.onChange} value={this.state.values.msg} />
                                            <label htmlFor="msg">Message</label>
                                        </div>
                                    </div>
                                    {this.state.sending ?
                                        <div className="row">
                                            <div className="preloader-wrapper small active">
                                                <div className="spinner-layer spinner-white-only">
                                                    <div className="circle-clipper left">
                                                        <div className="circle"></div>
                                                    </div><div className="gap-patch">
                                                        <div className="circle"></div>
                                                    </div><div className="circle-clipper right">
                                                        <div className="circle"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <div className="row">
                                            {this.state.errMessage ?
                                                <h5 className="red-text">{this.state.errMessage}</h5>
                                                :
                                                <div className="col s12 m4 l2">
                                                    <button type="submit" className="btn-large white red-text waves-effect waves-dark col s12" onClick={this.sendMessage}>Send</button>
                                                </div>
                                            }
                                        </div>
                                    }
                                </form>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Home;