import React from 'react';
import { Link } from 'react-router-dom';

const PageNotFound = props => (
    <div>
        <div className="row subnav" style={{ display: 'flex', justifyContent: 'space-around' }}>
            <div style={{ display: 'flex', justifyContent: 'space-evenly', position: 'absolute', bottom: '4px', left: '80px', fontSize: '1.5em' }}>
                <Link className="white-text" to="/">Home</Link>
                <span className="grey-text text-darken-2" style={{ marginLeft: '4px', marginRight: '4px', fontWeight: '500' }}>/</span>
                <span className="grey-text text-darken-2">404 Page Not Found</span>
            </div>
        </div>
        <div className="container" style={{minHeight: '500px'}}>
            <div className="row">
                <h1>404 Page Not Found</h1>
            </div>
            <div className="row">
                <Link to="/" style={{fontSize: '1.4em'}}>Return Home</Link>
            </div>
        </div>
    </div>
)

export default PageNotFound;