import React from 'react';
import { Link } from 'react-router-dom';
import chalkImage from '../assets/chalkboard.jpg';
import boxImage from '../assets/boxes.jpg';

const History = props => (
    <div>
        <div className="row subnav" style={{ display: 'flex', justifyContent: 'space-around' }}>
            <div style={{ display: 'flex', justifyContent: 'space-evenly', position: 'absolute', bottom: '4px', left: '80px', fontSize: '1.5em' }}>
                <Link className="white-text" to="/">Home</Link>
                <span className="grey-text text-darken-2" style={{ marginLeft: '4px', marginRight: '4px', fontWeight: '500' }}>/</span>
                <Link className="white-text" to="/articles">Articles</Link>
                <span className="grey-text text-darken-2" style={{ marginLeft: '4px', marginRight: '4px', fontWeight: '500' }}>/</span>
                <span className="grey-text text-darken-2">A Little History...</span>
            </div>
        </div>
        <div className="container">
            <div className="row">
                <h4>A little History</h4>
                <p className="grey-text">By <span className="red-text">Jacob Record</span></p>
            </div>
            <div className="row">
                <img className="responsive-img" src={chalkImage} alt="Chalkboard" />
            </div>
            <div className="row">
                <h5>Reverse Distribution 101</h5>
            </div>
            <div className="row">
                <p className="grey-text">So where did reverse distribution come from? Back in the mid-1980’s a few pharmacists realized returning expired medications to manufacturers for credit was a huge hassle. They took what they knew and started a new business selling their return service for a fee.  In these early days, reverse distributors returned medications one by one on behalf of each pharmacy they serviced.</p>
                <p className="grey-text">Eventually though, these early reverse distributors realized it was a lot of work to handle returns this way and they created the batch processing model. Returns companies learned they could cut costs if they grouped the returns from multiple pharmacies into one huge return. This allowed them to only track one return and save on shipping costs. Even with these savings though, they began charging additional fees for anything they could dream up- destruction fees, hazardous waste fees, shipping fees, label fees, handling fees, form fees, 222 fees, etc… One of the worst they charged was the minimum order fee. They’d charge you a fee for not having a large enough fee!</p>
                <p className="grey-text">While each reverse distributor out there has branded it something different, they all use this model.  The Batch method has been the standard for almost 30 years and is the root cause of many frustrations with the return industry. Because medications are lumped together, there is no way to tell whose medications are whose. This means every time a return is denied, there is no choice except to pass that loss on to every pharmacy with medications in that batch. This is why pharmacies frequently receive less than they expected.</p>
            </div>
            <div className="row">
                <img className="responsive-img" src={boxImage} alt="boxes" />
            </div>
            <div className="row">
                <p className="grey-text">Let me explain exactly why that is the case… Batching ensures returns companies cannot tell you what happened to your medications. They simply have no way of knowing. They work around this problem by assigning you a share of the batch so it looks like they know about your specific return. However, instead of getting the refund credit actually issued on your bottle of Amoxicillin by Teva, you may instead get .0542% of their Teva batch for the month. If any medications in that batch don’t get paid at full value, you share in that loss. These batches may be processed once a month, every other month or even every third month!</p>
                <p className="grey-text">The batching process also means returns companies are limited in the manufacturers they can return to. We’ve seen some companies that forego over 20% of the manufacturers they could potentially return to because they are difficult for them. Additionally, many manufacturers actually refuse to accept batched returns because they cannot verify original purchase information.</p>
                <p className="grey-text">Batched returns have been great for returns companies. Batching has lowered costs, simplified processes and increased profitability but it has come with a high cost to pharmacies. Reverse distributors have lost all accountability; they forego credit on many otherwise returnable products and they have made diversion much simpler and more common than it ever should have been.</p>
                <p className="grey-text">It’s been 30 years and it’s time for a change.</p>
            </div>
            <div className="row">
                <div className="col s12 m4 l2">
                    <Link to="/articles" className="btn-large col s12 red white-text">Back to Articles</Link>
                </div>
            </div>
        </div>
    </div>
)

export default History;