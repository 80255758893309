import React from 'react';
import { Link } from 'react-router-dom';
import deskTypesImage from '../assets/desktypes.jpg'
import handshakeImage from '../assets/handshake.jpg';

const CompoundingWaste = props => (
    <div>
        <div className="row subnav" style={{ display: 'flex', justifyContent: 'space-around' }}>
            <div style={{ display: 'flex', justifyContent: 'space-evenly', position: 'absolute', bottom: '4px', left: '80px', fontSize: '1.5em' }}>
                <Link className="white-text" to="/">Home</Link>
                <span className="grey-text text-darken-2" style={{ marginLeft: '4px', marginRight: '4px', fontWeight: '500' }}>/</span>
                <Link className="white-text" to="/articles">Articles</Link>
                <span className="grey-text text-darken-2" style={{ marginLeft: '4px', marginRight: '4px', fontWeight: '500' }}>/</span>
                <span className="grey-text text-darken-2">What To Do With Compounding Med Wastes</span>
            </div>
        </div>
        <div className="container">
            <div className="row">
                <h4>What to do with compound med waste?</h4>
                <p className="grey-text">By <span className="red-text">Jacob Record</span></p>
            </div>
            <div className="row">
                <img className="responsive-img" src={deskTypesImage} alt="People at Desk" />
            </div>
            <div className="row">
                <h5>One of the most frequently asked questions we hear is “Can you take back my compounding med waste?</h5>
            </div>
            <div className="row">
                <p className="grey-text">Compounding has become an important part of healthcare in the United States.  If your pharmacy has started compounding its own medications, you may be like many of the pharmacies we come across that have a large stock of old, outdated compounding powders and a large number of unused (or even partially used) compounded medications that were intended for a patient but never used.  Read on to find out how to handle those medications.</p>
                <p className="grey-text">Pharmacies have a range of different waste streams and it is important to understand which is which. Expired medications with an expectation of return credit go to a Reverse Distributor, like Outdate Rx. Expired medications with no expectation for credit (including partially used vials, open creams, open liquids, OTC's and compounded products) cannot be sent to a Reverse Distributor. Additionally, all pharmacies are required by law to segregate their medications that fall into the category of hazardous waste (RCRA) from their returnable products. These products and medications may not be sent to a Reverse Distributor under any circumstances.</p>
            </div>
            <div className="row">
                <img className="responsive-img" style={{ maxHeight: '300px' }} src={handshakeImage} alt="handshake" />
            </div>
            <div className="row">
                <p className="grey-text">Since compounded medications were created in the pharmacy they have no potential for return and reverse distributors are not allowed to take them.  This has left many pharmacies stuck without a clear direction on how to handle their compounding stock.</p>
                <p className="grey-text">In general, the answer is to have them destroyed utilizing your normal waste service provider.  This eliminates any risk of crossing laws regarding the transportation and disposal of medical waste. If you do not have a waste disposal service, contact us and we would be happy to connect you with a reputable company in your area that can handle your different waste streams.</p>
                <p className="grey-text">As always, if you have any questions, please reach out to us and we will be happy to help you find a safe, responsible solution for all your pharmaceutical needs.</p>
            </div>
            <div className="row">
                <div className="col s12 m4 l2">
                    <Link to="/articles" className="btn-large col s12 red white-text">Back to Articles</Link>
                </div>
            </div>
        </div>
    </div>
)

export default CompoundingWaste;