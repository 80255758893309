import React from 'react';
import { Link } from 'react-router-dom';

const Pricing = props => (
    <div>
        <div className="row subnav" style={{ display: 'flex', justifyContent: 'space-around' }}>
            <div style={{ display: 'flex', justifyContent: 'space-evenly', position: 'absolute', bottom: '4px', left: '80px', fontSize: '1.5em' }}>
                <Link className="white-text" to="/">Home</Link>
                <span className="grey-text text-darken-2" style={{ marginLeft: '4px', marginRight: '4px', fontWeight: '500' }}>/</span>
                <Link className="white-text" to="/services">Services</Link>
                <span className="grey-text text-darken-2" style={{ marginLeft: '4px', marginRight: '4px', fontWeight: '500' }}>/</span>
                <span className="grey-text text-darken-2">Service Option Pricing Table</span>
            </div>
        </div>
        <div className="container">
            <div className="row">
                <div className="col s12 l4">
                    <div className="card">
                        <div className="card-content" style={{ paddingLeft: '10px', paddingRight: '10px', paddingTop: '0px' }}>
                            <div className="red white-text row" style={{ padding: '40px' }}>
                                <div className="row">
                                    <h5 className="center">Self Service Mail-In</h5>
                                </div>
                                <div>
                                    <p className="center" style={{ lineHeight: '1.7em' }}>With this option our experts will process your returns utilizing out exclusive Isoturns reverse distribution model, resulting in:</p>
                                </div>
                            </div>
                            <div className="white grey-text">
                                <div className="row" style={{ paddingLeft: '20px' }}>
                                    <div style={{ display: 'flex', justifyContent: 'start', marginBottom: '8px' }}>
                                        <i className="red-text fas fa-check" style={{ marginRight: '12px', position: 'relative', top: '8px' }} />
                                        <p style={{ fontSize: '1.3em' }}>Faster Turnaround</p>
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'start', marginBottom: '8px' }}>
                                        <i className="red-text fas fa-check" style={{ marginRight: '12px', position: 'relative', top: '8px' }} />
                                        <p style={{ fontSize: '1.3em' }}>More manufacturer returns than batch return systems</p>
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'start', marginBottom: '8px' }}>
                                        <i className="red-text fas fa-check" style={{ marginRight: '12px', position: 'relative', top: '8px' }} />
                                        <p style={{ fontSize: '1.3em' }}>More accurate returns than batch return systems</p>
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'start', marginBottom: '8px' }}>
                                        <i className="red-text fas fa-check" style={{ marginRight: '12px', position: 'relative', top: '8px' }} />
                                        <p style={{ fontSize: '1.3em' }}>Web portal access</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <Link to="/contacts" className="btn black white-text col s8 offset-s2">MAKE AN APPOINTMENT</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col s12 l4">
                    <div className="card">
                        <div className="card-content" style={{ paddingLeft: '10px', paddingRight: '10px', paddingTop: '0px' }}>
                            <div className="red white-text row" style={{ padding: '40px' }}>
                                <div className="row">
                                    <h5 className="center">On-Site Service</h5>
                                </div>
                                <div>
                                    <p className="center" style={{ lineHeight: '1.7em' }}>This hybrid plan brings our service representative to your facility to assist with preparing your return.  It also includes:</p>
                                </div>
                            </div>
                            <div className="white grey-text">
                                <div className="row" style={{ paddingLeft: '20px' }}>
                                    <div style={{ display: 'flex', justifyContent: 'start', marginBottom: '8px' }}>
                                        <i className="red-text fas fa-check" style={{ marginRight: '12px', position: 'relative', top: '8px' }} />
                                        <p style={{ fontSize: '1.3em' }}>Free Shipping</p>
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'start', marginBottom: '8px' }}>
                                        <i className="red-text fas fa-check" style={{ marginRight: '12px', position: 'relative', top: '8px' }} />
                                        <p style={{ fontSize: '1.3em' }}>Free Packaging</p>
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'start', marginBottom: '8px' }}>
                                        <i className="red-text fas fa-check" style={{ marginRight: '12px', position: 'relative', top: '8px' }} />
                                        <p style={{ fontSize: '1.3em' }}>Free destruction of non-controlled, non-hazardous pharmaceuticals*</p>
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'start', marginBottom: '8px' }}>
                                        <i className="red-text fas fa-check" style={{ marginRight: '12px', position: 'relative', top: '8px' }} />
                                        <p style={{ fontSize: '1.3em' }}>Inventory of all Controlled Substances, including 222 forms when needed</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <Link to="/contacts" className="btn black white-text col s8 offset-s2">MAKE AN APPOINTMENT</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col s12 l4">
                    <div className="card">
                        <div className="card-content" style={{ paddingLeft: '10px', paddingRight: '10px', paddingTop: '0px' }}>
                            <div className="red white-text row" style={{ padding: '40px' }}>
                                <div className="row">
                                    <h5 className="center">Premium Service</h5>
                                </div>
                                <div>
                                    <p className="center" style={{ lineHeight: '1.7em' }}>This service ensures you don't even have to think about returns.  Our representatives do it all, including:</p>
                                </div>
                            </div>
                            <div className="white grey-text">
                                <div className="row" style={{ paddingLeft: '20px' }}>
                                    <div style={{ display: 'flex', justifyContent: 'start', marginBottom: '8px' }}>
                                        <i className="red-text fas fa-check" style={{ marginRight: '12px', position: 'relative', top: '8px' }} />
                                        <p style={{ fontSize: '1.3em' }}>Checking shelves for expired and short-dated products</p>
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'start', marginBottom: '8px' }}>
                                        <i className="red-text fas fa-check" style={{ marginRight: '12px', position: 'relative', top: '8px' }} />
                                        <p style={{ fontSize: '1.3em' }}>A Full inventory of all products in your return, including OTC's, legacy medications and controlled substances</p>
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'start', marginBottom: '16px' }}>
                                        <i className="red-text fas fa-check" style={{ marginRight: '12px', position: 'relative', top: '8px' }} />
                                        <p style={{ fontSize: '1.3em' }}>All-inclusive price </p>
                                    </div>
                                </div>
                                <div className="row">
                                    <Link to="/contacts" className="btn black white-text col s8 offset-s2">MAKE AN APPOINTMENT</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <p className="grey-text">If you have multiple facilities or large returns, please contact us to discuss discounts and other incentives Outdate Rx can provide.</p>
            </div>
            <div className="row">
                <p className="grey-text">Service Fee Percentage is based on the published value of the returnable medications and will be deducted from the actual credits received.  For other terms and conditions, please see our <Link to="/about/terms" className="red-text">terms and conditions</Link> page.</p>
            </div>
            <div className="row">
                <p className="grey-text">*Free destruction is limited to 25lbs per $1000 of returnable medications.  Excess destruction will be charged at $0.99 per pound.  For hazardous pharmaceutical products or controlled substances that need to be destroyed, please <Link to="/contacts" className="red-text">contact us</Link> to discuss specifics and options.</p>
            </div>
        </div>
    </div>
)

export default Pricing;