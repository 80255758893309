import React from 'react';
import { Link } from 'react-router-dom';

const Footer = props => (
    <footer className="page-footer white black-text">
        <div className="row grey lighten-4" style={{ padding: '16px' }}>
            <div className="col s12 m6 l3 offset-l3">
                <h6 style={{ fontWeight: '500' }}>About</h6>
                <div className="row">
                    <p>Outdate Rx is a reverse distributor that specializes in helping pharmacies recover the most credit from their returns.  Our unique Isoturns system ensures that no credit gets lost and each pharmacy gets exactly what they are entitled.</p>
                </div>
                <div className="row">
                    <p>Outdate Rx, the way returns should be done.</p>
                </div>
            </div>
            <div className="col s12 m6">
                <div className="row" style={{ padding: '16px' }}>
                    <h6 style={{ fontWeight: '500' }}>Services</h6>
                    <div className="row">
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <Link className="grey-text text-darken-2" to="/services/controls"><i className="red-text fas fa-check" style={{ fontSize: '.75em' }} /> Controlled Medications</Link>
                            <Link className="grey-text text-darken-2" to="/services/indates"><i className="red-text fas fa-check" style={{ fontSize: '.75em' }} /> Indates</Link>
                            <Link className="grey-text text-darken-2" to="/services/inventory"><i className="red-text fas fa-check" style={{ fontSize: '.75em' }} /> Inventory</Link>
                            <Link className="grey-text text-darken-2" to="/services/mail"><i className="red-text fas fa-check" style={{ fontSize: '.75em' }} /> Mail In</Link>
                            <Link className="grey-text text-darken-2" to="/services/onsite"><i className="red-text fas fa-check" style={{ fontSize: '.75em' }} /> On-Site</Link>
                            <Link className="grey-text text-darken-2" to="/services/recalls"><i className="red-text fas fa-check" style={{ fontSize: '.75em' }} /> Recalls</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
);

export default Footer;