import React from 'react';
import { Switch, Route } from 'react-router-dom';
import CompoundingWaste from './compoundingWaste';
import Isoturns from './isoturns';
import History from './history';
import ArticleOverview from './articleOverview';

const ArticleRouter = props => (
    <Switch>
        <Route exact path="/articles" component={ArticleOverview} />
        <Route exact path="/articles/compoundingwaste" component={CompoundingWaste} />
        <Route exact path="/articles/isoturns" component={Isoturns} />
        <Route exact path="/articles/history" component={History} />
    </Switch>
)

export default ArticleRouter;