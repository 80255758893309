import React from 'react';
import M from 'materialize-css';
import { Link } from 'react-router-dom';

class Lincenses extends React.Component {
    constructor(props) {
        super(props);
        this.state = { expanded: false }
    }

    componentDidMount = () => {
        M.Collapsible.init(document.querySelectorAll('.collapsible'), {
            onOpenStart: this.openStart,
            onCloseEnd: this.closeEnd
        });
    }

    componentWillUnmount = () => {
        document.querySelectorAll('.collapsible').forEach(el => {
            const coll = M.Collapsible.getInstance(el);
            if (coll)
                coll.destroy();
        })
    }

    openStart = el => {
        if (el.id === 'state')
            this.setState({ expanded: true })
    }

    closeEnd = el => {
        if (el.id === 'state')
            this.setState({ expanded: false })
    }

    render = () => (
        <div style={{ height: `CALC(100vh - ${this.state.expanded ? -780 : 300}px)` }}>
            <div className="row subnav" style={{ display: 'flex', justifyContent: 'space-around' }}>
                <div style={{ display: 'flex', justifyContent: 'space-evenly', position: 'absolute', bottom: '4px', left: '80px', fontSize: '1.5em' }}>
                    <Link className="white-text" to="/">Home</Link>
                    <span className="grey-text text-darken-2" style={{ marginLeft: '4px', marginRight: '4px', fontWeight: '500' }}>/</span>
                    <span className="grey-text text-darken-2">Licensing and Compliance</span>
                </div>
            </div>
            <div className="container">
                <div className="row">
                    <ul className="collapsible">
                        <li id="fed">
                            <div className="collapsible-header">Federal Licensing and Compliance</div>
                            <div className="collapsible-body">
                                <ul>
                                    <li>DEA</li>
                                    <li>EPA</li>
                                    <li>DOT</li>
                                </ul>
                            </div>
                        </li>
                        <li id="state">
                            <div className="collapsible-header">State Licenses</div>
                            <div className="collapsible-body">
                                <ul>
                                    <li>California</li>
                                    <li></li>
                                    <li>Alabama</li>
                                    <li>Alaska*</li>
                                    <li>Arizona</li>
                                    <li>Arakansas</li>
                                    <li>Colorado*</li>
                                    <li>Connecticut</li>
                                    <li>Delaware</li>
                                    <li>Florida*</li>
                                    <li>Georgia</li>
                                    <li>Hawaii*</li>
                                    <li>Idaho*</li>
                                    <li>Illinois</li>
                                    <li>Indiana</li>
                                    <li>Iowa</li>
                                    <li>Kansas</li>
                                    <li>Kentucky</li>
                                    <li>Louisiana*</li>
                                    <li>Maine*</li>
                                    <li>Maryland*</li>
                                    <li>Massachusetts*</li>
                                    <li>Michigan</li>
                                    <li>Minnesota</li>
                                    <li>Mississippi</li>
                                    <li>Missouri</li>
                                    <li>Montana*</li>
                                    <li>Nebraska*</li>
                                    <li>Nevada</li>
                                    <li>New Hampshire</li>
                                    <li>New Jersey*</li>
                                    <li>New Mexico*</li>
                                    <li>New York</li>
                                    <li>North Carolina</li>
                                    <li>North Dakota</li>
                                    <li>Ohio</li>
                                    <li>Oklahoma</li>
                                    <li>Oregon</li>
                                    <li>Pennsylvania*</li>
                                    <li>Rhode Island</li>
                                    <li>South Carolina</li>
                                    <li>South Dakota</li>
                                    <li>Tennessee</li>
                                    <li>Texas</li>
                                    <li>Utah*</li>
                                    <li>Vermont*</li>
                                    <li>Virginia*</li>
                                    <li>Washington</li>
                                    <li>West Virginia</li>
                                    <li>Wisconsin</li>
                                    <li>Wyoming*</li>
                                    <li></li>
                                    <li>* Indicates that these states do not require out of state reverse distributors to obtain separate in-state licensing.  CA license is the appropriate license for these locations.</li>
                                </ul>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default Lincenses;