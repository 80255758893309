import React from 'react';
import { Link } from 'react-router-dom';

const Controls = props => (
    <div>
        <div className="row subnav" style={{ display: 'flex', justifyContent: 'space-around' }}>
            <div style={{ display: 'flex', justifyContent: 'space-evenly', position: 'absolute', bottom: '4px', left: '80px', fontSize: '1.5em' }}>
                <Link className="white-text" to="/">Home</Link>
                <span className="grey-text text-darken-2" style={{ marginLeft: '4px', marginRight: '4px', fontWeight: '500' }}>/</span>
                <Link className="white-text" to="/services">Services</Link>
                <span className="grey-text text-darken-2" style={{ marginLeft: '4px', marginRight: '4px', fontWeight: '500' }}>/</span>
                <span className="grey-text text-darken-2">Controlled Medications</span>
            </div>
        </div>
        <div className="container">
            <div className="row">
                <h4>Controlled Medications</h4>
            </div>
            <div className="row">
                <h5>Service Overview</h5>
            </div>
            <div className="row">
                <p className="grey-text">Controlled substances can seem scary.  So scary, in fact, we sometimes see pharmacies who have hidden away all their expired controlled medications for years rather than dealing with them.  With so many regulations to follow, you need to know you can trust your reverse distributor to keep you on the right path.  </p>
                <p className="grey-text">At Outdate Rx, we make controlled medication returns simple.  Our representatives reconcile the medications on hand with your inventory log of what is to be removed from stock.  Then, they prepare all required documentation and securely pack the medications in DEA approved bags and shipping cartons.  Not only does this help you stay in compliance, it also can be a big source of return credits.  So stop hiding your controlled medications and give us a call.  We’re happy to help get you on the right path.</p>
            </div>
        </div>
    </div>
)

export default Controls;