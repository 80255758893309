import React from 'react';
import { Link } from 'react-router-dom';

const ArticleOverview = props => (
    <div>
        <div className="row subnav" style={{ display: 'flex', justifyContent: 'space-around' }}>
            <div style={{ display: 'flex', justifyContent: 'space-evenly', position: 'absolute', bottom: '4px', left: '80px', fontSize: '1.5em' }}>
                <Link className="white-text" to="/">Home</Link>
                <span className="grey-text text-darken-2" style={{ marginLeft: '4px', marginRight: '4px', fontWeight: '500' }}>/</span>
                <span className="grey-text text-darken-2">Articles</span>
            </div>
        </div>
        <div className="container" style={{minHeight: '500px'}}>
            <div className="row">
                <h3>Articles</h3>
            </div>
            <div className="row">
                <ul style={{fontSize: '1.5em', lineHeight: '2em'}}>
                    <li><Link to="/articles/history">A Little History...</Link></li>
                    <li><Link to="/articles/isoturns">What is Isoturns?</Link></li>
                    <li><Link to="/articles/compoundingwaste">What to do with compound med waste?</Link></li>
                </ul>
            </div>
        </div>
    </div>
)

export default ArticleOverview;