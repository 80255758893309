import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Contact from './contact';

const ContactRouter = props => (
    <Switch>
        <Route exact path="/contacts" component={Contact} />
    </Switch>
)

export default ContactRouter;