import React from 'react';
import { Link } from 'react-router-dom';
import onsiteImg from '../assets/onsite.jpg'

const OnSite = props => (
    <div>
        <div className="row subnav" style={{ display: 'flex', justifyContent: 'space-around' }}>
            <div style={{ display: 'flex', justifyContent: 'space-evenly', position: 'absolute', bottom: '4px', left: '80px', fontSize: '1.5em' }}>
                <Link className="white-text" to="/">Home</Link>
                <span className="grey-text text-darken-2" style={{ marginLeft: '4px', marginRight: '4px', fontWeight: '500' }}>/</span>
                <Link className="white-text" to="/services">Services</Link>
                <span className="grey-text text-darken-2" style={{ marginLeft: '4px', marginRight: '4px', fontWeight: '500' }}>/</span>
                <span className="grey-text text-darken-2">On-Site</span>
            </div>
        </div>
        <div className="container">
            <div className="row">
                <h4>On-Site Service</h4>
                <div className="row">
                    <img className="responsive-img" src={onsiteImg} alt="On-Site" />
                </div>
            </div>
            <div className="row">
                <h5>Service Overview</h5>
            </div>
            <div className="row">
                <p className="grey-text">We are pleased to offer you the option of having a well-trained service representative come to help with your expired medications. Our representatives will come to your facility, sort and package all returns according to DOT and DEA standards, and prepare the return for shipment. They will ensure the package is picked up by our carrier and safely transported back to our facility for processing.</p>
                <p className="grey-text">The service representatives ensure you don't have to spend your time dealing with the hassle of returns. They are also available to pull expired stock from your shelves, inventory expired medications being returned, and execute all documents related to the transfer of controlled substances.</p>
                <p className="grey-text">Outdate Rx cannot accept any hazardous waste (including RCRA medications, compounded items, OTC's or other medications with no expectation of credit from the manufacturer.) Outdate Rx is happy to connect you with a service provider that can safely transport and dispose of those products if needed.</p>
            </div>
        </div>
    </div>
)

export default OnSite;