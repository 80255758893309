import React from 'react';
import { Link } from 'react-router-dom';

const MailIn = props => (
    <div>
        <div className="row subnav" style={{ display: 'flex', justifyContent: 'space-around' }}>
            <div style={{ display: 'flex', justifyContent: 'space-evenly', position: 'absolute', bottom: '4px', left: '80px', fontSize: '1.5em' }}>
                <Link className="white-text" to="/">Home</Link>
                <span className="grey-text text-darken-2" style={{ marginLeft: '4px', marginRight: '4px', fontWeight: '500' }}>/</span>
                <Link className="white-text" to="/services">Services</Link>
                <span className="grey-text text-darken-2" style={{ marginLeft: '4px', marginRight: '4px', fontWeight: '500' }}>/</span>
                <span className="grey-text text-darken-2">Mail In</span>
            </div>
        </div>
        <div className="container">
            <div className="row">
                <h4>Mail In Service</h4>
            </div>
            <div className="row">
                <h5>Service Overview</h5>
            </div>
            <div className="row">
                <p className="grey-text">We are pleased to offer our convenient mail-in service option. For those times when you don’t have much to send back or when you have some extra time to process your own returns (and want to save a little money), give us a quick call and we will be happy to send you prepaid labels and a shipping guide.</p>
                <p className="grey-text">Mail in service is a good option when you want a little extra control over what goes in the box. Plus, we make controlled returns easy. Simply call or email us with an inventory and we will get you all the documents you need to ship your medications to us and get them off your inventory.</p>
                <p className="grey-text">Outdate Rx cannot accept any hazardous waste (including RCRA medications, compounded items, OTC's or other medications with no expectation of credit from the manufacturer.)  Outdate Rx is happy to connect you with a service provider that can safely transport and dispose of those products if needed.</p>
            </div>
        </div>
    </div>
)

export default MailIn;