import React from 'react';
import { Link } from 'react-router-dom';

const Services = props => (
    <div>
        <div className="row subnav" style={{ display: 'flex', justifyContent: 'space-around' }}>
            <div style={{ display: 'flex', justifyContent: 'space-evenly', position: 'absolute', bottom: '4px', left: '80px', fontSize: '1.5em' }}>
                <Link className="white-text" to="/">Home</Link>
                <span className="grey-text text-darken-2" style={{ marginLeft: '4px', marginRight: '4px', fontWeight: '500' }}>/</span>
                <span className="grey-text text-darken-2">Services</span>
            </div>
        </div>
        <div className="container">
            <div className="row">
                <div className="col s12 l4">
                    <div className="card-panel" style={{ backgroundImage: 'url(/assets/card-1.jpg)', backgroundSize: 'cover', minHeight: '600px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                        <div className="card center" style={{ backgroundColor: '#f44336cc', color: '#fff', minHeight: '480px' }}>
                            <div className="card-content">
                                <div className="row">
                                    <i className="far fa-edit white-text center" style={{ fontSize: '60px' }} />
                                </div>
                                <div className="row">
                                    <h4>On-Site Service</h4>
                                </div>
                                <div className="row col s8 offset-s2" style={{ fontSize: '1.2em' }}>
                                    <p className="center">We are pleased to offer you the option of having a well-trained service representative come to help with your expired...</p>
                                </div>
                                <div className="row">
                                    <div className="col s12 m8 offset-m2">
                                        <Link to="/services/onsite" className="btn-large white black-text" style={{ fontWeight: '500' }}>Read More</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col s12 l4">
                    <div className="card-panel" style={{ backgroundImage: 'url(/assets/card-2.jpg)', backgroundSize: 'cover', minHeight: '600px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                        <div className="card center" style={{ backgroundColor: '#f44336cc', color: '#fff', minHeight: '480px' }}>
                            <div className="card-content">
                                <div className="row">
                                    <i className="fa fa-coins white-text center" style={{ fontSize: '60px' }} />
                                </div>
                                <div className="row">
                                    <h4>Mail In Service</h4>
                                </div>
                                <div className="row col s8 offset-s2" style={{ fontSize: '1.2em' }}>
                                    <p className="center">We are pleased to offer our convenient mail in service option. For those times when you don’t have much to...</p>
                                </div>
                                <div className="row">
                                    <div className="col s12 m8 offset-m2">
                                        <Link to="/services/mail" className="btn-large white black-text" style={{ fontWeight: '500' }}>Read More</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col s12 l4">
                    <div className="card-panel" style={{ backgroundImage: 'url(/assets/card-3.jpg)', backgroundSize: 'cover', minHeight: '600px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                        <div className="card center" style={{ backgroundColor: '#f44336cc', color: '#fff', minHeight: '480px' }}>
                            <div className="card-content">
                                <div className="row">
                                    <i className="far fa-folder white-text center" style={{ fontSize: '60px' }} />
                                </div>
                                <div className="row">
                                    <h4>Recalls</h4>
                                </div>
                                <div className="row col s8 offset-s2" style={{ fontSize: '1.2em' }}>
                                    <p className="center">Recalls are a headache that no one wants to deal with so we’ve made the process simple for you. Simply...</p>
                                </div>
                                <div className="row">
                                    <div className="col s12 m8 offset-m2">
                                        <Link to="/services/recalls" className="btn-large white black-text" style={{ fontWeight: '500' }}>Read More</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col s12 l4">
                    <div className="card-panel" style={{ backgroundImage: 'url(/assets/card-4.jpg)', backgroundSize: 'cover', minHeight: '600px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                        <div className="card center" style={{ backgroundColor: '#f44336cc', color: '#fff', minHeight: '480px' }}>
                            <div className="card-content">
                                <div className="row">
                                    <i className="fas fa-search white-text center" style={{ fontSize: '60px' }} />
                                </div>
                                <div className="row">
                                    <h4>Inventory</h4>
                                </div>
                                <div className="row col s8 offset-s2" style={{ fontSize: '1.2em' }}>
                                    <p className="center">Outdate Rx is pleased to offer you the opportunity to pay for your inventory services using your return credits. Utilize our...</p>
                                </div>
                                <div className="row">
                                    <div className="col s12 m8 offset-m2">
                                        <Link to="/services/inventory" className="btn-large white black-text" style={{ fontWeight: '500' }}>Read More</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col s12 l4">
                    <div className="card-panel" style={{ backgroundImage: 'url(/assets/card-5.jpg)', backgroundSize: 'cover', minHeight: '600px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                        <div className="card center" style={{ backgroundColor: '#f44336cc', color: '#fff', minHeight: '480px' }}>
                            <div className="card-content">
                                <div className="row">
                                    <i className="fa fa-desktop white-text center" style={{ fontSize: '60px' }} />
                                </div>
                                <div className="row">
                                    <h4>Indated Medications</h4>
                                </div>
                                <div className="row col s8 offset-s2" style={{ fontSize: '1.2em' }}>
                                    <p className="center">Every pharmacy ends up with non-expired stock that they know will never be used.  Whether those products are the result...</p>
                                </div>
                                <div className="row">
                                    <div className="col s12 m8 offset-m2">
                                        <Link to="/services/indates" className="btn-large white black-text" style={{ fontWeight: '500' }}>Read More</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col s12 l4">
                    <div className="card-panel" style={{ backgroundImage: 'url(/assets/card-6.jpg)', backgroundSize: 'cover', minHeight: '600px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                        <div className="card center" style={{ backgroundColor: '#f44336cc', color: '#fff', minHeight: '480px' }}>
                            <div className="card-content">
                                <div className="row">
                                    <i className="fa fa-lock white-text center" style={{ fontSize: '60px' }} />
                                </div>
                                <div className="row">
                                    <h4>Controlled Medications</h4>
                                </div>
                                <div className="row col s8 offset-s2" style={{ fontSize: '1.2em' }}>
                                    <p className="center">Controlled substances can seem scary.  So scary, in fact, we sometimes see pharmacies who...</p>
                                </div>
                                <div className="row">
                                    <div className="col s12 m8 offset-m2">
                                        <Link to="/services/controls" className="btn-large white black-text" style={{ fontWeight: '500' }}>Read More</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
)

export default Services;